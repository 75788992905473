let firstClick = true;
const dropdown = document.querySelector(".dropdown-menu");
function hamburgerMenuClick() {
  if (firstClick) {
    dropdown.classList.add("dropdown-show");
  } else {
    dropdown.classList.remove("dropdown-show");
  }

  firstClick = !firstClick;
}

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

const accordions = document.querySelectorAll(".accordion-collapse");
let opening = false;
accordions.forEach(function (el) {
  el.addEventListener("hide.bs.collapse", (event) => {
    if (!opening) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      opening = false;
    }
  });
  el.addEventListener("show.bs.collapse", (event) => {
    opening = true;
  });
});
